#header a.logo {
  text-decoration: none;
}

#header a.logo:hover {
  text-decoration: underline;
}

a.header-link {
  color: #fff;
  text-decoration: none;
}

a.header-link:hover,
a.header-link:focus {
  text-decoration: underline;
}

.footer-link,
.about-link {
  color: #212529;
  text-decoration: none;
}

.footer-link:hover,
.footer-link:focus,
.about-link:hover,
.about-link:focus {
  color: #4d555a;
  text-decoration: underline;
}
